var googleAnalyticsId = process?.env?.VUE_APP_GOOGLE_TAG_MANAGER_ID || null

function activateGtm (w, d, s, l, i) {
  w[l] = w[l] || []
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

  var f = d.getElementsByTagName(s)[0]
  var j = d.createElement(s); var dl = l !== 'dataLayer' ? '&l=' + l : ''

  j.async = true
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
  f.parentNode.insertBefore(j, f)
}

googleAnalyticsId && activateGtm(window, document, 'script', 'dataLayer', googleAnalyticsId)
